import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { NewPayRoll } from '../../api/pay-rolls'
import paths from '../../constants/paths'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import IncomeTaxReport from '../../model/incomeTaxReport'
import Invoice from '../../model/invoice'
import OneTimePay, { OneTimePayCreationFields } from '../../model/oneTimePay'
import PayRoll from '../../model/payRoll'
import PayRollDeviation from '../../model/payRollDeviation'
import PaySlip from '../../model/paySlip'
import Shipment from '../../model/shipment'
import StripeConfiguration, { StripeConfigurationSetup } from '../../model/stripeConfiguration'
import TimeRegistration from '../../model/timeRegistration'
import Transfer, { PaymentMethod } from '../../model/transfer'
import { DateFormat } from '../../model/types'
import Voucher from '../../model/voucher'
import Warning from '../../model/warning'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { PayRollReducer } from '../../reducers/payRolls'
import { UserReducer } from '../../reducers/user'
import { formatDateTime, getDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { t, tx } from '../../utils/translation-utils'
import { isUserSupport } from '../../utils/user-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Modal from '../elements/modal'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AccountingCard from './AccountingCard'
import ApprovePayRoll, { ApproveResult } from './ApprovePayRoll'
import DeletePayRoll from './DeletePayRoll'
import DetailsCard from './DetailsCard'
import DeviationsCard from './DeviationsCard'
import EditDispositionDate, { DispositionDateResult } from './EditDispositionDate'
import FixNationalID from './FixNationalID'
import InvoiceCard from './InvoiceCard'
import NegatePayroll, { NegateResult } from './NegatePayroll'
import PaySlipsCard from './PaySlipsCard'
import RejectPayRoll from './RejectPayRoll'
import ReviewPayRoll, { ReviewResult } from './ReviewPayRoll'
import SwitchToManual from './SwitchToManual'
import SwitchToNETS from './SwitchToNETS'
import TaxReportsCard from './TaxReportsCard'
import TimelineCard from './TimelineCard'
import TimeRegistrationsCard from './TimeRegistrationsCard'
import TransfersCard from './TransfersCard'
import WarningsCard, { WarningEmployeeIDs } from './WarningsCard'

import './PayRollsSingle.css'

type Props = {
  alerts: AlertReducer
  user: UserReducer
  asynchronousTasks: List<AsynchronousTask>
  company: Company
  companyUsers: CompanyUserReducer
  payRoll: PayRoll
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  employeesMap: Record<string, Employee>
  employeesNumberMap: Record<string, Employee>
  departments: List<Department>
  timeRegistrations: List<TimeRegistration>
  payRolls: PayRollReducer
  payRollDeviations: List<PayRollDeviation>
  paySlips: List<PaySlip>
  vouchers: List<Voucher>
  transfers: List<Transfer>
  invoices: List<Invoice>
  incomeTaxReports: List<IncomeTaxReport>
  shipments: List<Shipment>
  warnings: List<Warning>
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addPayRoll: (payRoll: NewPayRoll) => Promise<PayRoll | void>
  approvePayRoll: (
    payRollID: string,
    version: string,
    approvalCode: string | undefined,
    userIDs: string[],
    channel: 'Email' | 'SMS'
  ) => void
  reviewPayRoll: (payRollID: string, version: string, userIDs: string[], channel: 'Email' | 'SMS') => void
  reopenPayRoll: (payRollID: string, version: string) => void
  rejectPayRoll: (payRollID: string, version: string) => void
  restartPayRoll: (payRollID: string, version: string) => void
  deletePayRoll: (payRollID: string) => void
  payRollSetDispositionDate: (payRollID: string, dispositionDate: DateFormat) => void
  payRollSetPaymentMethod: (payRollID: string, version: string, paymentMethod: PaymentMethod) => void
  deleteWarning: (warningID: string) => void
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
  resendSKAT: (payRollID: string, version: string) => void
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => Promise<OneTimePay | void>
}

export default function PayRollsSingle(props: Props): ReactElement | null {
  const [showApprovePayRoll, setShowApprovePayRoll] = useState(false)
  const [showReviewPayRoll, setShowReviewPayRoll] = useState(false)
  const [showRejectPayRoll, setShowRejectPayRoll] = useState(false)
  const [showDeletePayRoll, setShowDeletePayRoll] = useState(false)
  const [showEditDispositionDate, setShowEditDispositionDate] = useState(false)
  const [showSwitchToNETS, setShowSwitchToNETS] = useState(false)
  const [showSwitchToManual, setShowSwitchToManual] = useState(false)
  const [showWarnings, setShowWarnings] = useState(false)
  const [showNegatePayroll, setShowNegatePayRoll] = useState(false)
  const [showFixNationalID, setShowFixNationalID] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [approveKey, setApproveKey] = useState(1)
  const [reviewKey, setReviewKey] = useState(1)
  const [approved, setApproved] = useState(false)
  const [reviewed, setReviewed] = useState(false)
  const [approvers, setApprovers] = useState(
    props.payRoll ? props.payRoll.approvers.filter((approver) => approver.type === 'Approve').length : 0
  )
  const [rejected, setRejected] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const payRoll = props.payRoll
  const warningsList = props.warnings

  const filterWarnings = (warnings: List<Warning>, payRoll: PayRoll): Warning[] => {
    return warnings
      .filter((warning) => {
        switch (warning.warningType) {
          case 'EmployeeEmptyTaxCard':
          case 'EmployeeMissingTaxCard':
          case 'ManualTransfer':
          case 'NeedCreditCardIntegration':
          case 'UnapprovedCarAllowances':
          case 'UnapprovedReimbursements':
          case 'UnapprovedTimeRegistrations':
          case 'UnapprovedLeaveRegistrations':
          case 'UnapprovedOneTimePays':
          case 'VoucherMissingAccount':
          case 'EmployeesSharingTransferDestination':
          case 'ExcessVacationTaken':
          case 'EmployeeHasAssets':
          case 'TerminatedEmployeesInPeriod':
          case 'PromissoryNote':
          case 'ForcedNETSTransfer':
          case 'UnreadyEmployeesOnPayroll':
          case 'TINMissing':
          case 'TINNoMatch':
          case 'InsufficientPayslipVouchers':
          case 'EmployeeInvalidNationalID':
            return payRoll && warning.payRollID === payRoll.id
          default:
            return false
        }
      })
      .toArray()
  }

  useEffectOnce(() => {
    const warnings = filterWarnings(warningsList, payRoll)
    setShowWarnings(warnings.length <= 5)
  })

  const wasDeleted = usePrevious(deleted)

  useEffect(() => {
    if (!wasDeleted && deleted) {
      jsBrowserHistory.push('/' + paths.PAY_ROLLS)
    }
  }, [wasDeleted, deleted])

  const payRollsError = props.payRolls.error

  useEffect(() => {
    if (payRollsError !== error) {
      setApproved(false)
      setError(payRollsError)
    }
  }, [payRollsError, error])

  useEffect(() => {
    const newApprovers =
      payRoll && payRoll.approvers ? payRoll.approvers.filter((approver) => approver.type === 'Approve').length : 0
    if (newApprovers !== approvers) {
      setApproved(false)
      setApprovers(newApprovers)
    }
  }, [payRoll, approvers, setApproved, setApprovers])

  const payRollIsApproved = payRoll.isApproved
  const payRollIsTentative = payRoll.isTentative
  const payRollIsReviewed = payRoll.isReviewed
  const payRollIsRejected = payRoll.isRejected
  const payRollsSaving = props.payRolls.saving

  useEffect(() => {
    const isApproving = approved && !payRollIsApproved && !payRollIsTentative
    const isReviewing = reviewed && !payRollIsReviewed && !payRollIsApproved
    const isRejecting = rejected && !payRollIsRejected
    setIsSaving(payRollsSaving || isApproving || isReviewing || isRejecting)
  }, [
    approved,
    reviewed,
    rejected,
    payRollIsApproved,
    payRollIsTentative,
    payRollIsReviewed,
    payRollIsRejected,
    payRollsSaving,
    setIsSaving,
  ])

  const setApproveVisibility = (visible: boolean) => {
    setApproveKey(approveKey + 1)
    setShowApprovePayRoll(visible)
  }
  const setReviewVisibility = (visible: boolean) => {
    setReviewKey(reviewKey + 1)
    setShowReviewPayRoll(visible)
  }
  const setRejectVisibility = (visible: boolean) => {
    setShowRejectPayRoll(visible)
  }
  const setDeleteVisibility = (visible: boolean) => {
    setShowDeletePayRoll(visible)
  }
  const setEditDispositionDateVisibility = (visible: boolean) => {
    setShowEditDispositionDate(visible)
  }
  const setSwitchToNETSVisibility = (visible: boolean) => {
    setShowSwitchToNETS(visible)
  }
  const setSwitchToManualVisibility = (visible: boolean) => {
    setShowSwitchToManual(visible)
  }
  const setWarningsVisibility = (visible: boolean) => {
    setShowWarnings(visible)
  }
  const setNegatePayrollVisibility = (visible: boolean) => {
    setShowNegatePayRoll(visible)
  }
  const setFixNationalIDVisibility = (visible: boolean) => {
    setShowFixNationalID(visible)
  }

  const handleApprove = (values: ApproveResult) => {
    setApproveVisibility(false)
    setApproved(true)
    setApprovers(approvers + 1)
    props.approvePayRoll(payRoll.id, payRoll.version, values.approvalCode, values.userIDs, 'Email')
  }
  const handleReview = (values: ReviewResult) => {
    setReviewVisibility(false)
    setReviewed(true)
    props.reviewPayRoll(payRoll.id, payRoll.version, values.userIDs, values.channel)
  }
  const handleRevertApproval = (e: React.MouseEvent) => {
    e.preventDefault()

    setApprovers(0)
    props.reopenPayRoll(payRoll.id, payRoll.version)
  }
  const handleRestart = (e: React.MouseEvent) => {
    e.preventDefault()

    props.restartPayRoll(payRoll.id, payRoll.version)
  }
  const handleReject = (e: React.MouseEvent) => {
    e.preventDefault()

    setShowRejectPayRoll(false)
    setRejected(true)
    props.rejectPayRoll(payRoll.id, payRoll.version)
  }
  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault()

    setShowDeletePayRoll(false)
    setDeleted(true)
    props.deletePayRoll(payRoll.id)
  }
  const handleEditDispositionDate = (values: DispositionDateResult) => {
    setShowEditDispositionDate(false)
    props.payRollSetDispositionDate(payRoll.id, values.dispositionDate)
  }
  const handleRecreate = (e: React.MouseEvent) => {
    e.preventDefault()

    props.addPayRoll({
      companyID: props.company.id,
      type: 'Extra',
      salaryPeriodID: payRoll.salaryPeriod.id,
      dispositionDate: payRoll.dispositionDate,
      paySlipIDs: [],
    })
    jsBrowserHistory.push('/' + paths.PAY_ROLLS)
  }
  const handleSwitchToNETS = (e: React.MouseEvent) => {
    e.preventDefault()

    setShowSwitchToNETS(false)
    props.payRollSetPaymentMethod(payRoll.id, payRoll.version, 'NETS')
  }
  const handleSwitchToManual = (e: React.MouseEvent) => {
    e.preventDefault()

    setShowSwitchToManual(false)
    props.payRollSetPaymentMethod(payRoll.id, payRoll.version, 'Manual')
  }
  const handleNegatePayroll = (values: NegateResult) => {
    setShowFixNationalID(false)
    if (values.paySlipIDs.length === 0) {
      return // do nothing
    }
    props.addPayRoll({
      companyID: props.company.id,
      type: 'Negation',
      salaryPeriodID: payRoll.salaryPeriod.id,
      dispositionDate: payRoll.dispositionDate,
      paySlipIDs: values.paySlipIDs,
    })
    jsBrowserHistory.push('/' + paths.PAY_ROLLS)
  }

  const isAdmin = () => {
    if (props.user.userType === 'Admin' || props.user.userType === 'Support') {
      return true
    }
    // check admin permission
    return (
      props.companyUsers.companyUser &&
      props.companyUsers.companyUser.permissions.some((permission) => permission.permission === 'Admin')
    )
  }
  const isPayRollApprover = () => {
    if (props.user.userType === 'Admin' || props.user.userType === 'Support') {
      return true
    }
    // check user permission
    return (
      props.companyUsers.companyUser &&
      props.companyUsers.companyUser.permissions.some(
        (permission) =>
          permission.permission === 'Admin' ||
          permission.permission === 'ApprovePayRoll' ||
          permission.permission === 'ApproveOnly'
      )
    )
  }
  const canApprove = () => {
    if (!payRoll.canApprove) {
      return false
    }
    if (isAdmin()) {
      return true
    }
    if (payRoll.approvers.some((approver) => props.user.id === approver.userID && approver.type === 'Approve')) {
      return false
    }
    return isPayRollApprover()
  }
  const canReview = () => {
    if (!payRoll.canReview) {
      return false
    }
    if (props.company.numberOfPayRollReviewers === 0) {
      return false
    }
    if (isAdmin()) {
      return true
    }
    // check user permission
    return (
      props.companyUsers.companyUser &&
      props.companyUsers.companyUser.permissions.some(
        (permission) => permission.permission === 'Admin' || permission.permission === 'ReviewPayRoll'
      )
    )
  }
  const isFinished = () => {
    return (
      payRoll.status === 'Success' ||
      payRoll.status === 'Cancelled' ||
      payRoll.status === 'Failed' ||
      payRoll.status === 'PartialFailed'
    )
  }
  const isApproved = () => {
    if (!payRoll.isApproved) {
      return false
    }
    if (payRoll.status === 'Cancelled') {
      return false
    }
    if (payRoll.status === 'Failed') {
      return false
    }
    return true
  }
  const isPartialApproved = () => {
    if (!payRoll.isTentative) {
      return false
    }
    if (payRoll.status === 'Cancelled') {
      return false
    }
    if (payRoll.status === 'Failed') {
      return false
    }
    return true
  }
  const isReviewed = () => {
    if (!payRoll.isReviewed) {
      return false
    }
    if (payRoll.status === 'Cancelled') {
      return false
    }
    if (payRoll.status === 'Failed') {
      return false
    }
    return true
  }
  const isRejected = () => {
    if (!payRoll.isRejected) {
      return false
    }
    if (payRoll.status === 'Cancelled') {
      return false
    }
    if (payRoll.status === 'Failed') {
      return false
    }
    return true
  }
  const isDeletable = () => {
    return payRoll.isDeletable
  }
  const isCancelled = () => {
    return payRoll.status === 'Cancelled'
  }

  const canRevertApproval = () => {
    // Allow any user with approval rights to revert an approval
    if (isAdmin() || isPayRollApprover()) {
      return isApproved() || isPartialApproved() || isReviewed()
    }
    return payRoll.canRevertApproval
  }

  const canRecreate = () => {
    return payRoll.status === 'Cancelled' || payRoll.status === 'Failed'
  }
  const canSwitchToNETS = () => {
    if (isApproved()) {
      return false
    }
    if (payRoll.payRollType === 'Negation') {
      return false // can't switch to NETS on a negation payroll
    }
    if (payRoll.paymentMethod === 'NETS') {
      return false // can't if we have already forced it
    }

    if (payRoll.status === 'Cancelled' || payRoll.status === 'Failed' || payRoll.status === 'Success') {
      return false // cannot change on one that's done
    }

    if (
      !props.companyPaymentIntegrations.companyPaymentIntegrations.some(
        (v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired'
      )
    ) {
      // check if we can use NETS at all
      return false
    }

    // find interesting transfers, i.e. salary and freelance fees, and those that have actually DK accounts
    const transfers = props.transfers.filter((transfer) => {
      if (transfer.payRollID !== payRoll.id || transfer.completed) {
        return false
      }
      // types that cannot be switched
      if (transfer.type === 'DK Feriepenge Custom') {
        return false
      }
      if (transfer.type !== 'Salary' && transfer.type !== 'FreelanceFee') {
        return true // any other account can straight up be switched
      }
      if (!transfer.employeeID) {
        return false
      }

      const employee = props.employeesMap[transfer.employeeID]
      if (!employee) {
        return false
      }
      return (
        employee.transferDestinationType === 'DK Account' ||
        employee.transferDestinationType === 'DK NemKonto' ||
        employee.transferDestinationType === 'DK NemKonto CVR'
      )
    })

    // then check if they use manual, if so then we can switch
    return transfers.some((transfer) => transfer.paymentMethod === 'Manual')
  }
  const canSwitchToManual = () => {
    if (isApproved()) {
      return false
    }
    if (payRoll.paymentMethod === 'Manual') {
      return false // can't if we have already forced it
    }

    if (payRoll.status === 'Cancelled' || payRoll.status === 'Failed' || payRoll.status === 'Success') {
      return false // cannot change on one that's done
    }

    // find interesting transfers, i.e. salary and freelance fees
    const transfers = props.transfers.filter(
      (transfer) =>
        transfer.payRollID === payRoll.id &&
        !transfer.completed &&
        (transfer.type === 'Salary' || transfer.type === 'FreelanceFee')
    )

    // then check if they use NETS, if so then we can switch
    return transfers.some((transfer) => transfer.paymentMethod === 'NETS')
  }
  const canResendSKAT = () => {
    return (
      // only if our EIndkomstReply task failed
      payRoll.tasks.some(
        (task) => task.type === 'EIndkomstReply' && (task.status === 'Failed' || task.status === 'PartialFailed')
      ) &&
      // make sure they are sorted first, so the newest appear first
      // find should short-circuit as soon as it finds one that matches
      // then we check whether the replies have the codes we are interested in
      (props.shipments
        .sort((a, b) => b.sendTime.localeCompare(a.sendTime))
        .find((shipment) => shipment.workflowID === payRoll.id && shipment.type === 'DK EINDKOMST')
        ?.replies.some((r) =>
          r.notifications.some((n) => n.code === '225441' || n.code === '225443' || n.code === '225442')
        ) ||
        false)
    )
  }
  const hasFailureState = () => {
    return payRoll.status === 'Failed' || payRoll.tasks.some((task) => task.status === 'Failed')
  }

  const doResendSKAT = () => {
    if (confirm(t('pay_roll.single.main.buttons.resend_skat.confirm'))) {
      props.resendSKAT(payRoll.id, payRoll.version)
    }
  }

  const getWarnings = () => {
    // we reduce EmployeeInvalidNationalID to a single warning
    // but since we are here, we might as well turn it into an array rather than maintain it as a string
    return filterWarnings(props.warnings, payRoll).reduce((warnings: WarningEmployeeIDs[], warning) => {
      if (warning.warningType !== 'EmployeeInvalidNationalID') {
        return [...warnings, warning]
      }
      const employeeID = warning.subjectID
      if (!employeeID) {
        // we don't understand this warning
        return warnings
      }
      let found = false
      warnings = warnings.map((oldWarning) => {
        if (oldWarning.warningType !== warning.warningType) {
          return oldWarning
        }
        found = true
        oldWarning.employeeIDs = oldWarning.employeeIDs ? [...oldWarning.employeeIDs, employeeID] : [employeeID]
        return oldWarning
      })
      if (found) {
        return warnings
      }
      return [...warnings, { ...warning, subjectID: undefined, employeeIDs: [employeeID] }]
    }, [])
  }

  if (!payRoll) {
    return null
  }
  const task = payRoll.tasks.length > 0 ? payRoll.tasks[0] : null
  const canNegatePayroll =
    payRoll.payRollType !== 'Negation' &&
    props.company.settingsEnabled.some((setting) => setting === 'AllowNegationPayroll')
  const neededApprovers = props.company.numberOfPayRollApprovers - approvers
  const otherApprovers = props.companyUsers.companyUsers
    .filter(
      (companyUser) =>
        companyUser.userID !== props.user.id &&
        companyUser.permissions.some(
          (permission) =>
            permission.permission === 'ApprovePayRoll' ||
            permission.permission === 'Admin' ||
            permission.permission === 'ApproveOnly'
        ) &&
        !payRoll.approvers.some((approver) => companyUser.userID === approver.userID && approver.type === 'Approve')
    )
    .sort((a, b) => (a.name || a.email).localeCompare(b.name || b.email, 'da-dk'))
  const warnings = getWarnings()

  return (
    <div className="pay-rolls-single">
      <div className="pay-rolls-single-menu">
        {convertPayRollStatus(props.payRoll) === 'Awaiting' && (
          <LoadingOverlay
            text={
              <>
                {Object.keys(props.employeesMap).length > 250
                  ? t('pay_roll.single.main.awaiting.more_than_250')
                  : t('pay_roll.single.main.awaiting.less_than_250')}
                <br />
                {t('pay_roll.single.main.awaiting.lower_note')}
              </>
            }
          />
        )}
        {(canApprove() ||
          canReview() ||
          isReviewed() ||
          isApproved() ||
          isPartialApproved() ||
          isRejected() ||
          isDeletable() ||
          canRevertApproval() ||
          canRecreate() ||
          canResendSKAT()) && (
          <TitleMenu>
            <>
              {((isApproved() && canSwitchToNETS()) || canApprove() || canReview()) && (
                <span className="payroll-buttons">
                  {canSwitchToNETS() && (
                    <Button size="extra-extra-large" type="secondary" onClick={() => setSwitchToNETSVisibility(true)}>
                      {t('pay_roll.single.main.buttons.switch_to_nets')}
                    </Button>
                  )}
                  {canSwitchToManual() && (
                    <Button size="extra-extra-large" type="secondary" onClick={() => setSwitchToManualVisibility(true)}>
                      {t('pay_roll.single.main.buttons.switch_to_manual')}
                    </Button>
                  )}
                  {canReview() && (
                    <Button size="extra-extra-large" type="primary" onClick={() => setReviewVisibility(true)}>
                      {t('pay_roll.single.main.buttons.review')}
                    </Button>
                  )}
                  {canApprove() && (
                    <span>
                      {!isReviewed() && !isPartialApproved() && (
                        <span>
                          {props.company.rulePayRollRunApproval !== 'Automatic' && (
                            <Button
                              size="extra-extra-large"
                              onClick={() => setRejectVisibility(true)}
                              type="secondary"
                              prefixIcon="xSign"
                              danger
                            >
                              {t('pay_roll.single.main.buttons.reject')}
                            </Button>
                          )}
                          {isPayRollApprover() && isDeletable() && (
                            <Button
                              type="primary"
                              size="extra-extra-large"
                              danger
                              onClick={() => setDeleteVisibility(true)}
                              prefixIcon="xSign"
                            >
                              {t('pay_roll.single.main.buttons.delete')}
                            </Button>
                          )}
                          <Button
                            type="tertiary"
                            size="extra-extra-large"
                            onClick={handleRestart}
                            prefixIcon="roundedArrows"
                          >
                            {t('pay_roll.single.main.buttons.restart')}
                          </Button>
                        </span>
                      )}
                      <Button size="extra-extra-large" type="primary" onClick={() => setApproveVisibility(true)}>
                        {t('pay_roll.single.main.buttons.approve')}
                      </Button>
                    </span>
                  )}
                </span>
              )}
              {canApprove() && task && task.approvalType === 'Automatic' && (
                <p className="payroll-buttons-text">
                  {tx('pay_roll.single.main.header.automatic_approval', {
                    time: formatDateTime(task.approvalDeadline || getDate()),
                    link: (
                      <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/pay'}>
                        {t('pay_roll.single.main.header.automatic_approval.link')}
                      </Link>
                    ),
                  })}
                </p>
              )}
              {(isReviewed() || isApproved() || isPartialApproved()) && canRevertApproval() && (
                <Button
                  type="secondary"
                  danger
                  size="extra-extra-large"
                  onClick={handleRevertApproval}
                  prefixIcon="roundedArrows"
                >
                  {t('pay_roll.single.main.buttons.revert_approval')}
                </Button>
              )}
              {isReviewed() && !isApproved() && !isPartialApproved() && (
                <p className="button-text">{t('pay_roll.single.main.header.reviewed')}</p>
              )}
              {isApproved() && !isFinished() && (
                <p className="button-text">{t('pay_roll.single.main.header.approved')}</p>
              )}
              {isPartialApproved() && (
                <p className="button-text">
                  {t('pay_roll.single.main.header.partial_approved', { count: neededApprovers })}
                </p>
              )}
              {isRejected() && canRevertApproval() && (
                <Button
                  type="secondary"
                  danger
                  size="extra-extra-large"
                  onClick={handleRevertApproval}
                  prefixIcon="roundedArrows"
                >
                  {t('pay_roll.single.main.buttons.revert_approval')}
                </Button>
              )}
              {canRecreate() && (
                <Button type="tertiary" size="extra-extra-large" onClick={handleRecreate} prefixIcon="roundedArrows">
                  {t('pay_roll.single.main.buttons.recreate')}
                </Button>
              )}
              {isFinished() && canNegatePayroll && (
                <Button
                  type="tertiary"
                  danger
                  size="extra-extra-large"
                  onClick={() => setNegatePayrollVisibility(true)}
                  prefixIcon="xSign"
                >
                  {t('pay_roll.single.main.buttons.negate')}
                </Button>
              )}
              {(isCancelled() || hasFailureState()) && isDeletable() && (
                <Button type="primary" size="extra-extra-large" danger onClick={handleDelete} prefixIcon="xSign">
                  {t('pay_roll.single.main.buttons.delete')}
                </Button>
              )}
              {canResendSKAT() && (
                <Button
                  type="tertiary"
                  size="extra-extra-large"
                  onClick={() => doResendSKAT()}
                  prefixIcon="roundedArrows"
                >
                  {t('pay_roll.single.main.buttons.resend_skat')}
                </Button>
              )}
              {isFinished() && !canRecreate() && !canNegatePayroll && (
                <p className="button-text">{t('pay_roll.single.main.header.finished')}</p>
              )}
            </>
            {isSaving && <LoadingOverlay />}
          </TitleMenu>
        )}
        <Title>{t('pay_roll.single.main.title', { name: props.company.name })}</Title>
        <div className="back-btn">
          <Button onClick={() => jsBrowserHistory.goBack()}>{t('general.back.button')}</Button>
        </div>
      </div>
      <div className="pay-rolls-single-main">
        {warnings.length > 0 && (
          <div>
            <Alert
              message={
                <DumbLink onClick={() => setWarningsVisibility(true)}>
                  {t(
                    showWarnings
                      ? 'pay_roll.single.main.notification.notifications.shown'
                      : 'pay_roll.single.main.notification.notifications.hidden',
                    { count: warnings.length }
                  )}
                </DumbLink>
              }
              type={'warning'}
              showIcon
              closable={false}
            />
          </div>
        )}
        <Row>
          <Col span={16}>
            <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

            <div className="pay-roll-overview">
              {error && <Alert message={formatError(error)} type="error" showIcon />}
              <WarningsCard
                payRoll={payRoll}
                expanded={showWarnings}
                transfers={props.transfers}
                warnings={warnings}
                employeesMap={props.employeesMap}
                setWarningsVisibility={setWarningsVisibility}
                deleteWarning={props.deleteWarning}
                canSwitchToNETS={canSwitchToNETS()}
                isSupportUser={isUserSupport(props.user)}
                setSwitchToNETSVisibility={() => setSwitchToNETSVisibility(true)}
                setFixNationalIDVisibility={() => setFixNationalIDVisibility(true)}
              />
              <DeviationsCard
                company={props.company}
                payRoll={payRoll}
                payRollDeviations={props.payRollDeviations}
                employeesMap={props.employeesMap}
              />
              <TimeRegistrationsCard
                payRoll={payRoll}
                employeesMap={props.employeesMap}
                timeRegistrations={props.timeRegistrations}
                paySlips={props.paySlips}
              />
              <PaySlipsCard
                payRoll={payRoll}
                employeesMap={props.employeesMap}
                departments={props.departments}
                paySlips={props.paySlips}
              />
              <TransfersCard
                payRoll={payRoll}
                employeesMap={props.employeesMap}
                departments={props.departments}
                transfers={props.transfers}
                addAlert={props.addAlert}
              />
              <AccountingCard
                payRoll={payRoll}
                user={props.user}
                asynchronousTasks={props.asynchronousTasks}
                company={props.company}
                companyAccountingIntegration={props.companyAccountingIntegration}
                vouchers={props.vouchers}
                addAlert={props.addAlert}
              />
              <InvoiceCard invoices={props.invoices} payRoll={payRoll} />
              <TaxReportsCard
                payRoll={payRoll}
                employeesNumberMap={props.employeesNumberMap}
                incomeTaxReports={props.incomeTaxReports}
              />
            </div>
          </Col>
          <Col span={8}>
            <DetailsCard
              payRoll={payRoll}
              shipments={props.shipments}
              companyUsers={props.companyUsers.companyUsers}
              setEditDispositionDateVisibility={setEditDispositionDateVisibility}
            />
            <TimelineCard payRoll={payRoll} />
          </Col>
        </Row>
      </div>

      <Modal
        key={`approve-${approveKey}`}
        visible={showApprovePayRoll}
        onOk={() => setApproveVisibility(false)}
        onCancel={() => setApproveVisibility(false)}
        width={400}
        footer={null}
      >
        <ApprovePayRoll
          onSubmit={handleApprove}
          hasDeadline={!!(task && task.approvalDeadline)}
          neededApprovers={neededApprovers}
          companyPaymentIntegrations={props.companyPaymentIntegrations}
          company={props.company}
          invoices={props.invoices}
          approvers={otherApprovers}
          addAlert={props.addAlert}
          closeModal={() => setApproveVisibility(false)}
          addStripeConfiguration={props.addStripeConfiguration}
          updateStripeConfiguration={props.updateStripeConfiguration}
        />
      </Modal>
      <Modal
        key={`review-${reviewKey}`}
        visible={showReviewPayRoll}
        onOk={() => setReviewVisibility(false)}
        onCancel={() => setReviewVisibility(false)}
        width={400}
        footer={null}
      >
        <ReviewPayRoll
          onSubmit={handleReview}
          company={props.company}
          approvers={otherApprovers}
          user={props.user}
          addAlert={props.addAlert}
          closeModal={() => setReviewVisibility(false)}
        />
      </Modal>
      <Modal
        visible={showRejectPayRoll}
        onOk={() => setRejectVisibility(false)}
        onCancel={() => setRejectVisibility(false)}
        width={400}
        footer={null}
      >
        <RejectPayRoll rejectPayRoll={handleReject} />
      </Modal>
      <Modal
        visible={showDeletePayRoll}
        onOk={() => setDeleteVisibility(false)}
        onCancel={() => setDeleteVisibility(false)}
        width={400}
        footer={null}
      >
        <DeletePayRoll payRolls={props.payRolls.payRolls} payRoll={props.payRoll} deletePayRoll={handleDelete} />
      </Modal>
      <Modal
        visible={showEditDispositionDate}
        onOk={() => setEditDispositionDateVisibility(false)}
        onCancel={() => setEditDispositionDateVisibility(false)}
        width={400}
        footer={null}
      >
        <EditDispositionDate dispositionDate={payRoll.dispositionDate} onSubmit={handleEditDispositionDate} />
      </Modal>
      <Modal
        visible={showSwitchToNETS}
        onOk={() => setSwitchToNETSVisibility(false)}
        onCancel={() => setSwitchToNETSVisibility(false)}
        width={400}
        footer={null}
      >
        <SwitchToNETS switchToNETS={handleSwitchToNETS} />
      </Modal>
      <Modal
        visible={showSwitchToManual}
        onOk={() => setSwitchToManualVisibility(false)}
        onCancel={() => setSwitchToManualVisibility(false)}
        width={400}
        footer={null}
      >
        <SwitchToManual switchToManual={handleSwitchToManual} />
      </Modal>
      <Modal
        visible={showNegatePayroll}
        onOk={() => setNegatePayrollVisibility(false)}
        onCancel={() => setNegatePayrollVisibility(false)}
        width={400}
        footer={null}
      >
        <NegatePayroll paySlips={props.paySlips} onSubmit={handleNegatePayroll} />
      </Modal>
      <Modal
        visible={showFixNationalID}
        onOk={() => setFixNationalIDVisibility(false)}
        onCancel={() => setFixNationalIDVisibility(false)}
        width={600}
        footer={null}
      >
        <FixNationalID
          employeesMap={props.employeesMap}
          warning={warnings.find((warning) => warning.warningType === 'EmployeeInvalidNationalID')}
          company={props.company}
          paySlips={props.paySlips}
          payRoll={payRoll}
          addPayRoll={props.addPayRoll}
          updateEmployee={props.updateEmployee}
          addOneTimePay={props.addOneTimePay}
        />
      </Modal>
    </div>
  )
}
