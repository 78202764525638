import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { acceptAgreement, getAgreements } from './actions/agreements'
import { BootstrapResult, getBootstrap } from './actions/bootstrap'
import { getCompany, getSupplementTypes, updateActiveCompany } from './actions/companies'
import { getCompanyFeatures } from './actions/company-features'
import { getCompanyGroups } from './actions/company-groups'
import { addCompanyPricingPackage, getCompanyPricingPackages } from './actions/company-pricing-packages'
import { getCompanyPricings } from './actions/company-pricings'
import { getCompanyUsers } from './actions/company-users'
import { getDashboards } from './actions/dashboards'
import { getEmployees } from './actions/employees'
import { getLeaveTypes } from './actions/leave-types'
import { addModal, removeModal } from './actions/modals'
import { getPricingPackages } from './actions/pricing-packages'
import { getSalaryTypes } from './actions/salary-types'
import { logout } from './actions/user'
import { getWarnings } from './actions/warnings'
import { deleteCompanyDeletion } from './api/companies'
import Alert from './components/elements/alert'
import Button from './components/elements/button/button'
import Modal from './components/elements/modal'
import GlobalMessage from './components/header/GlobalMessage'
import Header from './components/header/Header'
import Landing from './components/landing/Landing'
import MainMenu from './components/main-menu/MainMenu'
import Modals from './components/modals/Modals'
import GDPRTerms from './components/widgets/GDPRTerms'
import jsBrowserHistory from './components/widgets/jsBrowserHistory'
import ResponsiveNoteModal from './components/widgets/ResponsiveNoteModal'
import UserSurvey from './components/widgets/UserSurvey'
import paths from './constants/paths'
import { needCompany } from './controllers/jumper/Jumper'
import { AgreementType } from './model/agreement'
import { ModalOption, ModalType } from './model/modal'
import { UITheme } from './model/user'
import { AgreementReducer } from './reducers/agreements'
import { BootstrapReducer } from './reducers/bootstrap'
import { CompanyReducer } from './reducers/companies'
import { CompanyFeatureReducer } from './reducers/companyFeatures'
import { CompanyGroupReducer } from './reducers/companyGroups'
import { CompanyPaymentIntegrationReducer } from './reducers/companyPaymentIntegrations'
import { CompanyPricingPackageReducer } from './reducers/companyPricingPackages'
import { CompanyPricingReducer } from './reducers/companyPricings'
import { CompanyUserReducer } from './reducers/companyUsers'
import { DashboardReducer } from './reducers/dashboards'
import { EmployeeReducer } from './reducers/employees'
import { GlobalMessageReducer } from './reducers/globalMessage'
import { LeaveTypeReducer } from './reducers/leaveTypes'
import { ModalReducer } from './reducers/modals'
import { OneTimePayReducer } from './reducers/oneTimePays'
import { PricingPackageReducer } from './reducers/pricingPackages'
import { SalaryTypeReducer } from './reducers/salaryTypes'
import { SupplementTypeReducer } from './reducers/supplementTypes'
import { UserReducer } from './reducers/user'
import { UserCompanyReducer } from './reducers/userCompanies'
import { WarningReducer } from './reducers/warnings'
import Language from './types/language'
import { formatDateTime } from './utils/date-utils'
import { isRequestError } from './utils/error-utils'
import { getCurrentLanguage, setCurrentLanguage } from './utils/language-utils'
import { logWarning } from './utils/log-utils'
import {
  defaultMenuContainer,
  MainMenuAdministrationID,
  MainMenuCompanyCompanyID,
  MainMenuCompanyID,
  MainMenuCompanyPayID,
  MainMenuCompanyTransferID,
  MainMenuIntegrationID,
  MainMenuRegistrationID,
  MainMenuTopID,
  MenuContainer,
  MenuContext,
  setMainMenuAdministrationMenu,
  setMainMenuCompanyMenu,
  setMainMenuIntegrationMenu,
  setMainMenuRegistrationMenu,
  setMainMenuTopMenu,
} from './utils/menu-utils'
import { hasDepartmentPermission, isDepartmentRestricted } from './utils/permissions-utils'
import { getCompanyPricingPackage, isPricingPackageGroup } from './utils/pricing-package-utils'
import { connectToReducer } from './utils/reducer-utils'
import { isOutsidePage, isResponsivePage, isSSOPage } from './utils/request-utils'
import { RouteProps } from './utils/route-utils'
import { t } from './utils/translation-utils'

import './App.css'
import './theme-light.css'
import './theme-classic.css'

declare global {
  interface Window {
    Intercom: any
  }
}

type Reducers = {
  modals: ModalReducer
  bootstrap: BootstrapReducer
  user: UserReducer
  companies: CompanyReducer
  companyGroups: CompanyGroupReducer
  dashboards: DashboardReducer
  agreements: AgreementReducer
  employees: EmployeeReducer
  companyUsers: CompanyUserReducer
  companyFeatures: CompanyFeatureReducer
  companyPricings: CompanyPricingReducer
  companyPricingPackages: CompanyPricingPackageReducer
  leaveTypes: LeaveTypeReducer
  pricingPackages: PricingPackageReducer
  salaryTypes: SalaryTypeReducer
  supplementTypes: SupplementTypeReducer
  globalMessage: GlobalMessageReducer
  warnings: WarningReducer
  oneTimePays: OneTimePayReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  userCompanies: UserCompanyReducer
}

type Actions = {
  getBootstrap: (
    justLoggedIn: boolean,
    includeActiveCompany?: boolean,
    forceSettingActiveCompany?: boolean
  ) => Promise<BootstrapResult | void>
  updateActiveCompany: (id: string | null) => void
  getAgreements: () => void
  acceptAgreement: (type: 'GDPR_1.0') => void
  getEmployees: () => void
  getCompanyUsers: () => void
  getCompanyFeatures: () => void
  getCompanyPricings: () => void
  getCompanyPricingPackages: () => void
  addCompanyPricingPackage: (pricingPackageID: string) => void
  getLeaveTypes: () => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  getSupplementTypes: () => void
  getSalaryTypes: () => void
  getWarnings: () => void
  getCompanyGroups: () => void
  getDashboards: (companyID: string) => void
  getCompany: (companyID: string) => void
  removeModal: (id: number) => void
  logout: () => Promise<boolean | void>
  addModal: (type: ModalType, options: ModalOption) => void
}

type AdditionalProps = RouteProps & {
  children: ReactNode
}

function App(props: Reducers & Actions & AdditionalProps): ReactElement | null {
  const [minHeight, setMinHeight] = useState(window.innerHeight - 54)
  const [showResponsiveNoteModal, setShowResponsiveNoteModal] = useState(false)
  const [knownCurrentLanguage, setKnownCurrentLanguage] = useState<Language>(getCurrentLanguage())
  const [hashDocument, setHashDocument] = useState<string>()
  const [mainMenu, setMainMenu] = useState<MenuContainer>(defaultMenuContainer)
  const [theme, setTheme] = useState<UITheme>('Light')

  const updateMinHeight = () => {
    setMinHeight(window.innerHeight - 54)
  }

  const ssoPrePage = isSSOPage(props.location.pathname)

  const isJumpPage = props.location.pathname.indexOf('/' + paths.JUMP) === 0
  const isAboutToBeJumpPage =
    props.location.pathname.indexOf('/' + paths.LOGIN) === 0 &&
    props.location.query?.ref?.indexOf('/' + paths.JUMP) === 0

  const { updateActiveCompany } = props

  const setActiveCompany = useCallback(
    (companyID: string | null) => {
      updateActiveCompany(companyID)
    },
    [updateActiveCompany]
  )

  useEffect(() => {
    switch (theme) {
      case 'Classic':
        document.body.setAttribute('class', 'theme-classic')
        break
      default:
        // light is our default theme for now (even for Dark)
        document.body.setAttribute('class', 'theme-light')
        break
    }
  }, [theme])

  const { user, bootstrap, getBootstrap, location } = props
  useEffectOnce(() => {
    window.addEventListener('resize', updateMinHeight)

    if (!isOutsidePage(location.pathname)) {
      if (user.loggedIn && !bootstrap.loaded) {
        getBootstrap(false, !ssoPrePage)
      }
    }

    return () => window.removeEventListener('resize', updateMinHeight)
  })

  const {
    companies,
    agreements,
    getAgreements,
    employees,
    getEmployees,
    companyUsers,
    getCompanyUsers,
    companyFeatures,
    getCompanyFeatures,
    companyPricings,
    getCompanyPricings,
    leaveTypes,
    getLeaveTypes,
    supplementTypes,
    getSupplementTypes,
    salaryTypes,
    getSalaryTypes,
    companyGroups,
    getCompanyGroups,
    dashboards,
    getDashboards,
    warnings,
    getWarnings,
  } = props

  const previousUser = usePrevious(user)

  useEffect(() => {
    if (user.user?.uiSettings.theme) {
      setTheme(user.user?.uiSettings.theme)
    }
  }, [user])

  useEffect(() => {
    if (bootstrap?.error?.message === 'Company requires SAML Access') {
      getBootstrap(true, false)
    }
  }, [bootstrap, getBootstrap])

  useEffect(() => {
    if (isOutsidePage(location.pathname) || isSSOPage(location.pathname)) {
      return // no handling for these phone pages
    }
    const newCompanyID = location.query.companyID
    if (newCompanyID && (!companies.company || newCompanyID !== companies.company.id)) {
      setActiveCompany(newCompanyID)
      jsBrowserHistory.push(location.pathname)
      return
    }
    if (!isJumpPage && needCompany(companies, location.pathname, true)) {
      return
    }
    if (previousUser && !previousUser.loggedIn && user.loggedIn) {
      // just logged in
      if (user.user?.uiSettings.showCompanyListOnLogIn) {
        setActiveCompany(null)
      }
    }
    if (previousUser && !previousUser.loggedIn && user.loggedIn) {
      jsBrowserHistory.push(location.query.ref || '/')
    }
    const userJustLoggedIn = !!previousUser && !previousUser.loggedIn && user.loggedIn
    const bootstrapInitiated = bootstrap.loading || bootstrap.loaded
    if (userJustLoggedIn && !bootstrapInitiated) {
      getBootstrap(true, !ssoPrePage, isJumpPage || isAboutToBeJumpPage)
    }
  }, [
    companies,
    bootstrap,
    getBootstrap,
    isAboutToBeJumpPage,
    isJumpPage,
    location,
    previousUser,
    user,
    setActiveCompany,
    ssoPrePage,
  ])

  useEffect(() => {
    if (!isJumpPage && needCompany(companies, location.pathname, false)) {
      return
    }
    if (isOutsidePage(location.pathname) || isSSOPage(location.pathname)) {
      return // no handling for these phone pages
    }
    if (bootstrap.loaded) {
      const companyID = companies.company?.id
      if ((agreements.companyID && agreements.companyID !== companyID) || (!agreements.loading && !agreements.loaded)) {
        getAgreements()
      }
      if (!employees.loading && !employees.loaded) {
        getEmployees()
      }
      if (!companyUsers.loading && !companyUsers.loaded) {
        getCompanyUsers()
      }
      if (!companyFeatures.loading && !companyFeatures.loaded) {
        getCompanyFeatures()
      }
      if (!companyPricings.loading && !companyPricings.loaded) {
        getCompanyPricings()
      }
      if (!leaveTypes.loading && !leaveTypes.loaded) {
        getLeaveTypes()
      }
      if (!supplementTypes.loading && !supplementTypes.loaded) {
        getSupplementTypes()
      }
      if (!salaryTypes.loading && !salaryTypes.loaded) {
        getSalaryTypes()
      }
      if (!companyGroups.loading && !companyGroups.loaded) {
        getCompanyGroups()
      }
      if (companyID && !dashboards.loading && !dashboards.loaded) {
        getDashboards(companyID)
      }
    }
    if (!warnings.loading && !warnings.loaded) {
      getWarnings()
    }
  }, [
    companies,
    agreements,
    getAgreements,
    bootstrap,
    getBootstrap,
    companyFeatures,
    getCompanyFeatures,
    companyGroups,
    getCompanyGroups,
    companyPricings,
    getCompanyPricings,
    companyUsers,
    getCompanyUsers,
    dashboards,
    getDashboards,
    employees,
    getEmployees,
    leaveTypes,
    getLeaveTypes,
    salaryTypes,
    getSalaryTypes,
    supplementTypes,
    getSupplementTypes,
    warnings,
    getWarnings,
    isAboutToBeJumpPage,
    isJumpPage,
    location,
    user,
    setActiveCompany,
    ssoPrePage,
  ])

  const getMaxWidth = () => {
    const parts = props.location.pathname.split('/')
    // Remove max width on dashboard
    if (parts.length === 2 && parts[0] === '' && parts[1] === '') {
      return 'none'
    }
    // Remove max width on employee page
    if (
      (parts.length === 3 || parts.length === 4 || parts.length === 5) &&
      parts[1] === paths.EMPLOYEES &&
      parts[2] !== paths.ADD
    ) {
      return 'none'
    }
    // Remove max width on freelancer page
    if (
      (parts.length === 3 || parts.length === 4 || parts.length === 5) &&
      parts[1] === paths.FREELANCERS &&
      parts[2] !== paths.ADD
    ) {
      return 'none'
    }
    // Remove max width on account page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.ACCOUNT) {
      return 'none'
    }
    // Remove max width on company page
    if (
      (parts.length === 3 || parts.length === 4 || parts.length === 5) &&
      parts[1] === paths.COMPANIES &&
      parts[2] !== paths.ADD
    ) {
      return 'none'
    }
    // Remove max width on company dashboards
    if (parts.length === 2 && parts[1] === paths.DASHBOARD) {
      return 'none'
    }
    // Remove max width on pay roll page
    if (parts.length === 3 && parts[1] === paths.PAY_ROLLS) {
      return 'none'
    }
    // Remove max width on time registration page
    if (parts.length >= 2 && parts[1] === paths.TIME_REGISTRATION) {
      return 'none'
    }
    // Remove max width on salary registration page
    if (parts.length === 2 && parts[1] === paths.SALARY_REGISTRATION) {
      return 'none'
    }
    // Remove max width on leave registration page
    if (parts.length >= 2 && parts[1] === paths.LEAVE_REGISTRATION) {
      return 'none'
    }
    // Remove max width on car allowance page
    if (parts.length === 2 && parts[1] === paths.CAR_ALLOWANCE) {
      return 'none'
    }
    // Remove max width on one time pays page
    if (parts.length === 2 && parts[1] === paths.ONE_TIME_PAYS) {
      return 'none'
    }
    // Remove max width on work hours page
    if (parts.length === 2 && parts[1] === paths.WORK_HOURS) {
      return 'none'
    }
    // Remove max width on freelancers overview page
    if (parts.length === 2 && parts[1] === paths.FREELANCERS_OVERVIEW) {
      return 'none'
    }
    // Remove max width on approve tab page
    if (parts.length === 2 && parts[1] === paths.APPROVE_TAB) {
      return 'none'
    }
    // Remove max width on Swipe overview page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.SWIPE_OVERVIEW) {
      return 'none'
    }
    // Remove max width on integrations page
    if ((parts.length === 3 || parts.length === 4 || parts.length === 5) && parts[1] === paths.INTEGRATIONS) {
      return 'none'
    }
    // Remove max width on reimbursement voucher page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.REIMBURSEMENT_VOUCHERS) {
      return 'none'
    }
    // Remove max width on assets page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.ASSETS) {
      return 'none'
    }
    // Remove max width on a documents page
    if (parts.length >= 2 && parts[1] === paths.DOCUMENTS) {
      return 'none'
    }
    // Remove max width on a projects page
    if (parts.length >= 2 && parts[1] === paths.PROJECTS) {
      return 'none'
    }
    // Remove max width on a company groups page
    if (parts.length >= 2 && parts[1] === paths.COMPANY_GROUPS) {
      return 'none'
    }
    // Remove max width on companies page if the user is admin of the current company group
    if (parts.length === 2 && parts[1] === paths.COMPANIES) {
      const companyGroup = props.companyGroups.companyGroup
      if (companyGroup) {
        return 'none'
      }
    }
    return '1240px'
  }

  useEffect(() => {
    // update whenever the user's language is not what we currently think is the language
    if (user.language && user.language !== knownCurrentLanguage) {
      setCurrentLanguage(user.language)
      setKnownCurrentLanguage(user.language)
    }
  }, [user, knownCurrentLanguage])

  // the following handles scrolling to an element when the hash for it is set
  // of course, if the element never shows up, this will keep running forever
  // but it will only run if an actual hash is given, which is seldom the case
  const scrollToHash = useCallback((hash: string) => {
    const e = document.getElementById(hash.replace(/^#/, ''))
    if (!e) {
      setTimeout(() => {
        scrollToHash(hash)
      }, 500)
      return
    }
    const list = document.getElementsByClassName('target-element')
    for (let i = 0; i < list.length; i++) {
      list[i].className = list[i].className.replace(/ ?target-element$/, '')
    }
    e.className = e.className + ' target-element'
    e.scrollIntoView({ block: 'center' })
    setHashDocument(hash)
  }, [])

  useEffect(() => {
    if (!location.hash || location.hash === hashDocument) {
      return
    }
    scrollToHash(location.hash)
  }, [location, hashDocument, scrollToHash])

  //handle keyboard event to bring up search input

  const eventHandler = useCallback(
    (e: KeyboardEvent) => {
      const { code, ctrlKey } = e
      if ('KeyK' === code && ctrlKey) {
        e.preventDefault()
        props.addModal('global-search', { width: 900 })
      }
    },
    [props]
  )

  useEffect(() => {
    document.addEventListener('keydown', eventHandler)
    return () => document.removeEventListener('keydown', eventHandler)
  }, [props.user, eventHandler])

  const company = companies.company

  // BEGIN MAIN MENU HANDLING ---
  useEffect(() => {
    const hasPendingApprovals = (): boolean => {
      if (company) {
        const dashboard = dashboards.dashboards.find((dashboard) => dashboard.id === company.id)
        if (dashboard) {
          return (
            (dashboard.carAllowancesPending || 0) > 0 ||
            (dashboard.swipePending || 0) > 0 ||
            (dashboard.leaveRegistrationsPending || 0) > 0 ||
            (dashboard.timeRegistrationsPending || 0) > 0 ||
            (dashboard.reimbursementsPending || 0) > 0
          )
        }
      }
      return false
    }
    const onlyDepartments = isDepartmentRestricted(companyUsers.companyUser)

    const canSeeSalaryRates = hasDepartmentPermission(companyUsers.companyUser, undefined, 'SeeSalaryRates')
    const hasContractBookFeature = companyFeatures.companyFeatures.some(
      (feature) => feature.featureType === 'ContractBook'
    )
    const items: MainMenuTopID[] = []
    items.push('main-menu-global-search')
    if (company) {
      if (!onlyDepartments) {
        items.push('main-menu-pay-rolls')
      }
      items.push('main-menu-employees')
      items.push('main-menu-freelancers')
      if (hasPendingApprovals()) {
        items.push('main-menu-registrations-approval')
      } else {
        items.push('main-menu-registrations')
      }
      if (canSeeSalaryRates) {
        items.push('main-menu-administration-documents')
      } else {
        items.push('main-menu-administration')
      }
      if (!onlyDepartments) {
        items.push('main-menu-company')
      }
      if (!onlyDepartments || hasContractBookFeature) {
        if (onlyDepartments) {
          items.push('main-menu-integrations-department-only')
        } else {
          items.push('main-menu-integrations')
        }
      }
    }
    if (!onlyDepartments && companies.companies.size > 1) {
      items.push('main-menu-dashboard')
    }
    setMainMenuTopMenu(setMainMenu, company?.id, items)
  }, [companies, company, companyFeatures, companyUsers, dashboards, user])

  // REGISTRATION MENU
  const { oneTimePays } = props

  useEffect(() => {
    const items: MainMenuRegistrationID[] = []
    if (company) {
      items.push('registration-menu-approve-tab')
      items.push('registration-menu-time-registration')
      if (
        employees.employees.some(
          (employee) =>
            !!employee.earliestMutableContract &&
            !!employee.earliestMutableContract.remuneration &&
            employee.earliestMutableContract.remuneration.salary.some((salary) =>
              salaryTypes.salaryTypes.some(
                (salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'SupplementVaried'
              )
            )
        )
      ) {
        items.push('registration-menu-salary-registration')
      }
      items.push('registration-menu-leave-registration')
      items.push('registration-menu-car-allowances')
      items.push('registration-menu-work-hours')
      if (hasDepartmentPermission(companyUsers.companyUser, undefined, 'SeeSalaryRates')) {
        items.push('registration-menu-one-time-pays')
      }
      items.push('registration-menu-reimbursement-vouchers')
      items.push('registration-menu-freelancers')
      if (company.enableSwipe || oneTimePays.oneTimePays.some((oneTimePay) => oneTimePay.type === 'Swipe')) {
        items.push('registration-menu-swipes')
      }
    }
    setMainMenuRegistrationMenu(setMainMenu, items)
  }, [company, employees, salaryTypes, companyUsers, oneTimePays, companyFeatures])

  // ADMINISTRATION MENU

  useEffect(() => {
    const canSeeSalaryRates = hasDepartmentPermission(companyUsers.companyUser, undefined, 'SeeSalaryRates')

    const items: MainMenuAdministrationID[] = []
    if (canSeeSalaryRates) {
      items.push('administration-menu-documents')
    }
    items.push('administration-menu-assets')
    items.push('administration-menu-projects')

    setMainMenuAdministrationMenu(setMainMenu, items)
  }, [companyFeatures, companyUsers, company])

  // COMPANY MENU
  const { companyPaymentIntegrations, pricingPackages, companyPricingPackages, userCompanies } = props

  useEffect(() => {
    const hasNETSIntegration = (): boolean => {
      return companyPaymentIntegrations.companyPaymentIntegrations.some(
        (v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired'
      )
    }

    const isAdmin = (): boolean => {
      return (
        companyUsers.companyUsers
          .find((companyUser) => companyUser.companyID === company?.id && companyUser.userID === user.id)
          ?.permissions?.some((v) => v.permission === 'Admin') || false
      )
    }

    // main
    const items: (MainMenuCompanyID | MainMenuCompanyCompanyID | MainMenuCompanyPayID | MainMenuCompanyTransferID)[] = [
      'company-menu-company',
      'company-menu-pay',
      'company-menu-invoices',
      'company-menu-transfers',
      'company-menu-departments',
      'company-menu-departments',
      'company-menu-pricing',
    ]
    if (isAdmin()) {
      items.push('company-menu-api-keys')
    }
    items.push('company-menu-support', 'company-menu-reports')
    if (
      isPricingPackageGroup(
        pricingPackages.pricingPackages.toArray(),
        companyPricingPackages.companyPricingPackages.toArray(),
        company?.id,
        ['Automatic', 'Premium']
      )
    ) {
      items.push('company-menu-club')
    }

    // company
    items.push(
      'company-menu-company-menu-users',
      'company-menu-company-menu-production-units',
      'company-menu-company-menu-vacation-settings',
      'company-menu-company-menu-advance-settings'
    )

    // pay
    items.push('company-menu-pay-menu-user-notifications', 'company-menu-pay-menu-salary-types')
    if (
      userCompanies.userCompanies
        .find((userCompany) => userCompany.companyID === company?.id)
        ?.permissions?.some((permission) => permission.permission === 'Admin') ||
      user.userType === 'Admin' ||
      user.userType === 'Support'
    ) {
      items.push('company-menu-pay-menu-deviations')
    }
    if (hasNETSIntegration()) {
      items.push('company-menu-pay-menu-nets-messages')
    }

    // transfers
    items.push('company-menu-transfers-menu-settings')
    if (hasNETSIntegration()) {
      if (companyFeatures.companyFeatures.some((feature) => feature.featureType === 'DK NemKonto')) {
        items.push('company-menu-transfers-menu-transfer-nemkonto')
      }
      if (
        companyFeatures.companyFeatures.some(
          (feature) => feature.featureType === 'Immediate Pay' || feature.featureType === 'Swipe'
        )
      ) {
        items.push('company-menu-transfers-menu-immediate-pay')
      }
    }

    setMainMenuCompanyMenu(setMainMenu, items)
  }, [
    companyFeatures,
    company,
    companyUsers,
    user,
    companyPaymentIntegrations,
    userCompanies,
    companyPricingPackages,
    pricingPackages,
  ])

  // INTEGRATION MENU
  useEffect(() => {
    const departmentManager = company && isDepartmentRestricted(companyUsers.companyUser)

    const isDefinitelyNotAnAccountant = () => {
      // if they do not have this signup role, they MIGHT be an accountant
      if (!user.signupCompanyRole || user.signupCompanyRole !== 'Company Owner, Runs Pay Roll') {
        return false
      }
      // more than one company?  Probably an accountant
      if (companies.companies.size !== 1) {
        return false
      }
      // no company?!  Weird accountant...
      if (!company) {
        return false
      }
      // Now find the company's pricing package, and make sure it's not the Accountant one
      return (
        getCompanyPricingPackage(
          pricingPackages.pricingPackages.toArray(),
          companyPricingPackages.companyPricingPackages.toArray(),
          company.id
        )?.name !== 'Accountant'
      ) // then we might be able to presume they are probably _not_ an accountant
    }
    const items: MainMenuIntegrationID[] = []
    if (!departmentManager) {
      items.push('integration-menu-accounting')
    }
    if (companyFeatures.companyFeatures.some((feature) => feature.featureType === 'ContractBook')) {
      items.push('integration-menu-contract-book')
    }
    if (!departmentManager) {
      items.push(
        'integration-menu-data-provider',
        'integration-menu-codan',
        'integration-menu-pension',
        'integration-menu-min-refusion',
        'integration-menu-rmindr',
        'integration-menu-personalezonen',
        'integration-menu-slack'
      )
      if (isDefinitelyNotAnAccountant()) {
        items.push('integration-menu-advisor')
      }
    }
    setMainMenuIntegrationMenu(setMainMenu, items)
  }, [companies, company, companyFeatures, companyPricingPackages, companyUsers, user, pricingPackages])

  // END MAIN MENU HANDLING -----

  const previousLanguage = usePrevious(knownCurrentLanguage)
  const languageJustChanged = previousLanguage && previousLanguage !== knownCurrentLanguage

  const hasAcceptedGDPR =
    !props.companies.company ||
    props.agreements.agreements.filter((agreement) => agreement.type === 'GDPR_1.0').size > 0

  if (
    !isResponsivePage(props.location.pathname) &&
    !showResponsiveNoteModal &&
    hasAcceptedGDPR &&
    screen.width < 1280
  ) {
    document.getElementById('viewport')?.setAttribute('content', 'width=1280')
  } else {
    document.getElementById('viewport')?.setAttribute('content', 'width=device-width, initial-scale=1')
  }

  const acceptAgreement = (t: AgreementType) => {
    props.acceptAgreement(t)

    if (screen.width < 1040) {
      setShowResponsiveNoteModal(true)
    }
  }

  if (isOutsidePage(props.location.pathname)) {
    if (props.location.pathname === '/' + paths.PAYROLL_APPROVE_PHONE_SITE && window.Intercom) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: props.user.email,
        user_id: props.user.id,
      })
    }
    return (
      <div lang={knownCurrentLanguage} className="phone-page-wrapper">
        {props.children}
      </div>
    )
  }

  if (!props.user.loggedIn) {
    return <>{props.children}</>
  }

  if (
    !props.companies.loaded ||
    (!ssoPrePage &&
      props.companies.company &&
      (!props.agreements.loaded || !props.companyUsers.loaded || !props.employees.loaded)) ||
    (ssoPrePage && !props.companies.loaded)
  ) {
    return <Landing />
  }

  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: props.user.email,
      user_id: props.user.id,
    })
  }

  const removeCompanyDeletion = () => {
    const companyID = company?.id
    if (!companyID) {
      return
    }
    deleteCompanyDeletion(companyID).catch((e) => {
      if (isRequestError(e)) {
        logWarning(e)
      }
    })
  }

  const normalMode = (hasAcceptedGDPR && !showResponsiveNoteModal) || ssoPrePage

  return (
    <div
      lang={knownCurrentLanguage}
      className={'site-wrapper' + (props.user.user?.uiSettings.sideMenu ? ' with-side-menu' : '')}
    >
      <MenuContext.Provider value={{ menu: mainMenu, setMenu: setMainMenu }}>
        {props.globalMessage.message && <div className="global-message">{props.globalMessage.message}</div>}
        {company?.deletionScheduledAt && (
          <Alert
            type="error"
            style={{ fontSize: '18pt', height: '58px', borderRadius: '0' }}
            message={
              <>
                {t('global_message.company_scheduled_for_deletion', {
                  deletionScheduledAt: formatDateTime(company?.deletionScheduledAt),
                })}
                <Button
                  type="secondary"
                  danger
                  size="large"
                  onClick={removeCompanyDeletion}
                  style={{ float: 'right', fontSize: '16pt' }}
                >
                  {t('global_message.company_scheduled_for_deletion.button')}
                </Button>
              </>
            }
          />
        )}
        <GlobalMessage companies={props.companies} globalMessage={props.globalMessage} />
        {props.user.user?.uiSettings.sideMenu ? (
          <MainMenu
            location={props.location}
            user={props.user}
            companies={props.companies}
            companyUsers={props.companyUsers}
            companyGroups={props.companyGroups.companyGroups}
            employees={props.employees}
            warnings={props.warnings}
            pricingPackages={props.pricingPackages}
            companyPricingPackages={props.companyPricingPackages}
            setActiveCompany={setActiveCompany}
            logout={props.logout}
            currentLanguage={knownCurrentLanguage}
            setCurrentLanguage={setKnownCurrentLanguage}
            getPricingPackages={props.getPricingPackages}
            addModal={props.addModal}
          />
        ) : (
          <Header
            location={props.location}
            user={props.user}
            companies={props.companies}
            companyUsers={props.companyUsers}
            companyGroups={props.companyGroups.companyGroups}
            warnings={props.warnings}
            setActiveCompany={setActiveCompany}
            logout={props.logout}
            currentLanguage={knownCurrentLanguage}
            setCurrentLanguage={setKnownCurrentLanguage}
            addModal={props.addModal}
          />
        )}
        <div
          className="main-wrapper"
          style={{
            maxWidth: getMaxWidth(),
            minHeight: `${minHeight}px`,
          }}
        >
          {normalMode && !languageJustChanged && props.children}
          {normalMode &&
            languageJustChanged &&
            React.Children.map(props.children, (child) =>
              // React does not re-render props.children, even when this component's state changes.
              // We can however force this by using this trick.
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              React.cloneElement(child, { currentLanguage: knownCurrentLanguage })
            )}
          {!hasAcceptedGDPR && !ssoPrePage && (
            <Modal visible closable={false} width={579} footer={null}>
              <GDPRTerms agreements={props.agreements.agreements} acceptAgreement={acceptAgreement} />
            </Modal>
          )}
          {normalMode && (
            <Modals
              companies={props.companies}
              companyPricingPackages={props.companyPricingPackages}
              modals={props.modals}
              pricingPackages={props.pricingPackages}
              removeModal={props.removeModal}
              getCompanyPricingPackages={props.getCompanyPricingPackages}
              addCompanyPricingPackage={props.addCompanyPricingPackage}
              getPricingPackages={props.getPricingPackages}
              employees={props.employees}
            />
          )}

          <Modal
            visible={showResponsiveNoteModal}
            onOk={() => setShowResponsiveNoteModal(false)}
            onCancel={() => setShowResponsiveNoteModal(false)}
            footer={null}
            className="responsive-note"
          >
            <ResponsiveNoteModal closeModal={() => setShowResponsiveNoteModal(false)} />
          </Modal>

          <UserSurvey />
        </div>
      </MenuContext.Provider>
    </div>
  )
}

export default connectToReducer<Reducers, Actions, AdditionalProps>(
  (state) => ({
    modals: state.modals,
    bootstrap: state.bootstrap,
    user: state.user,
    dashboards: state.dashboards,
    companies: state.companies,
    companyGroups: state.companyGroups,
    agreements: state.agreements,
    employees: state.employees,
    companyUsers: state.companyUsers,
    companyFeatures: state.companyFeatures,
    companyPricings: state.companyPricings,
    companyPricingPackages: state.companyPricingPackages,
    leaveTypes: state.leaveTypes,
    pricingPackages: state.pricingPackages,
    supplementTypes: state.supplementTypes,
    salaryTypes: state.salaryTypes,
    globalMessage: state.globalMessage,
    warnings: state.warnings,
    oneTimePays: state.oneTimePays,
    companyPaymentIntegrations: state.companyPaymentIntegrations,
    userCompanies: state.userCompanies,
  }),
  {
    removeModal: removeModal,
    getBootstrap: getBootstrap,
    getDashboards: getDashboards,
    updateActiveCompany: updateActiveCompany,
    getAgreements: getAgreements,
    acceptAgreement: acceptAgreement,
    getEmployees: getEmployees,
    getCompanyGroups: getCompanyGroups,
    getCompanyUsers: getCompanyUsers,
    getCompanyFeatures: getCompanyFeatures,
    getCompanyPricings: getCompanyPricings,
    getCompanyPricingPackages: getCompanyPricingPackages,
    addCompanyPricingPackage: addCompanyPricingPackage,
    getLeaveTypes: getLeaveTypes,
    getPricingPackages: getPricingPackages,
    getSupplementTypes: getSupplementTypes,
    getSalaryTypes: getSalaryTypes,
    getWarnings: getWarnings,
    getCompany: getCompany,
    logout: logout,
    addModal: addModal,
  }
)(App)
